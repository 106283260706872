import type { FunctionComponent } from 'react'
import React from 'react'
import { Checkbox, TypographyV2 as Typography } from '@which/seatbelt'

import styles from './PICheckboxFilter.module.scss'

export const PICheckboxFilter: FunctionComponent<Props> = ({
  data,
  onChangeCallback,
  filterType,
  parentText,
}) => {
  return (
    <div data-testid="ea-checkbox-filter" className={styles.checkboxFilter}>
      {data.map(({ label, count, value, checked }, index) => {
        const ariaLabelCheckedTxt = checked ? 'checked' : 'unchecked'

        return (
          <div key={`${value}-${index}`} className={styles.checkboxWrapper}>
            <Checkbox
              checked={checked}
              id={value}
              label=""
              labelChildren={
                <div data-testid="ea-checkbox-label" className={styles.checkboxWrapperLabel}>
                  {label}
                  <Typography tag="span" textStyle="sb-text-body-default-regular">
                    {` (${count})`}
                  </Typography>
                </div>
              }
              value={value}
              name={value}
              ariaLabel={`${label}, ${count} articles available, ${ariaLabelCheckedTxt}`}
              onChangeCallback={() => onChangeCallback({ id: value, type: filterType, parentText })}
              aria-controls="ea-search-results"
            />
          </div>
        )
      })}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  data: {
    label: string
    count: number
    value: string
    checked: boolean
  }[]
  onChangeCallback: any
  filterType?: string
  parentText?: string
}
